import { View } from 'react-native';
import { styled } from '@gluestack-style/react';
export default styled(View, {}, {
    componentName: 'InputAccessoryView',
    resolveProps: ['backgroundColor'],
}, {
    propertyResolver: {
        backgroundColor: (rawValue, resolver) => {
            return resolver(rawValue);
        },
    },
});
