import { styled } from '@gluestack-style/react';
import { View } from 'react-native';
export default styled(View, {}, {
    componentName: 'LinearGradient',
    resolveProps: ['colors'],
}, {
    propertyTokenMap: {
        colors: 'colors',
    },
    propertyResolver: {
        colors: (rawValue, resolver) => {
            rawValue.forEach((color, index) => {
                rawValue[index] = resolver(color);
            });
            return rawValue;
        },
    },
});
